/**
 * UnitDetail
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/* MUI */
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

/* Icons */
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ScheduleIcon from '@mui/icons-material/CalendarMonth';

/* Data */
import {
  EnumDKeyDeviceType,
  EnumPanelType,
  Panel,
  Property,
  Unit,
  usePropertyDataForBuildingQuery,
  useUnitDetailQuery,
  useUnitDetailUpdateSubscription,
  Zone,
} from '../../../types/generated-types';

/* Components */
import { UnitDevice } from '../helpers';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';

import { DeviceGroupingDetail } from '../deviceGroupings/device-grouping-detail';
import { IconButton, useMediaQuery } from '@mui/material';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import {
  CubicFeetPerMinute,
  Fahrenheit,
} from '../../system/models/temperatureUnits';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { basePropertyMenuItems } from '../properties/ui/base-property-context-menu';
import { Icon } from '@iconify/react';

/* Convenience Types */
export type MyDevice = Partial<UnitDevice>;

export type DeviceGrouping = Partial<Zone> | Partial<Panel>;

export function UnitDetail({
  installerView = false,
}: {
  installerView?: boolean;
}) {
  const { id: propertyId, unitId } = useParams<{
    id: string;
    unitId: string;
  }>();
  const theme = useTheme();
  const { user } = useAuthenticator();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
    setSubtitleActionWidget,
  } = useInjectableComponents();
  useUnitDetailUpdateSubscription({
    variables: { ids: [unitId ?? '0'] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const { data, loading, error } = useUnitDetailQuery({
    variables: { id: unitId || '' },
  });

  const {
    data: propertyData,
    loading: propertyLoading,
    error: propertyError,
  } = usePropertyDataForBuildingQuery({
    variables: { id: propertyId || '' },
  });

  useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById as Partial<Property>);
    }
  }, [propertyData]);

  const [unit, setUnit] = useState<Partial<Unit>>();
  const [property, setProperty] = useState<Partial<Property>>();
  const [groupings, setGroupings] = useState<DeviceGrouping[]>([]);

  const groupingSortBasis = (grouping: DeviceGrouping): string => {
    if (grouping.__typename === 'Zone') {
      return `=${grouping.name}`;
    } else if (grouping.__typename === 'Panel') {
      const role = grouping.role;
      const type = grouping.type;

      switch (type) {
        case EnumPanelType.Gpio:
          return `0_${grouping.name}`;
        case EnumPanelType.Device:
          switch (role) {
            case EnumDKeyDeviceType.PowerMonitor:
              return `1_${grouping.name}`;
            case EnumDKeyDeviceType.Boiler:
              return `2_${grouping.name}`;
            case EnumDKeyDeviceType.Chiller:
              return `2_${grouping.name}`;
            case 'DomesticHotWater':
              return `4_${grouping.name}`;
            case 'OpenWeather':
              return `5_${grouping.name}`;
            default:
              return `9_${grouping.name}`;
          }
        case EnumPanelType.Loop:
          return `3_${grouping.name}`;
        default:
          return `8_${grouping.name}`;
      }
    } else {
      throw new Error('Unknown grouping type');
    }
  };

  useEffect(() => {
    if (unit?._id) {
      setSubtitleActionWidget({
        widget: (
          <div>
            <IconButton
              aria-label="view unit schedule"
              sx={{
                color: theme.palette.secondary.main,
                backgroundColor: '#FFF',
              }}
              onClick={() =>
                navigate(
                  `/properties/${property?._id}/units/${unit?._id}/schedule`,
                )
              }
            >
              <ScheduleIcon />
            </IconButton>

            <IconButton
              aria-label="view unit information"
              sx={{
                color: theme.palette.secondary.main,
                backgroundColor: '#FFF',
              }}
              onClick={() =>
                navigate(
                  `/properties/${property?._id}/units/${unit?._id}/view-unit`,
                )
              }
            >
              <Icon icon="mdi:information-outline" />
            </IconButton>
          </div>
        ),
      });
    }
  }, [unit]);

  useEffect(() => {
    if (data?.unitById) {
      const unit: Partial<Unit> = data.unitById as unknown as Partial<Unit>;
      setUnit(unit);
      const deviceGroupings = [
        ...(unit.zones as Partial<Zone>[]),
        ...(unit.panels as Partial<Panel>[]),
      ];

      const filteredGroupings = installerView
        ? deviceGroupings
        : deviceGroupings
            .filter(
              (grouping) =>
                !!grouping?.devices?.find(
                  (device) =>
                    (device as unknown as { isConfigured: boolean })
                      .isConfigured,
                ),
            )
            .sort((a, b) =>
              groupingSortBasis(a).localeCompare(groupingSortBasis(b)),
            );

      setGroupings(filteredGroupings);
    }
  }, [data, installerView]);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (property && unit) {
      const pathComponent = pathname.split('/');

      /* Only populate the list of tools here if we are in fact on the correct (non-installer) path */
      if (!pathComponent.includes('installer')) {
        const items: BottomMenuItems = [
          ...basePropertyMenuItems(property._id, pathname),
        ];

        const editPath = [...pathComponent, 'edit-unit'].join('/');
        const viewPath = [...pathComponent, 'view-unit'].join('/');

        items.push({
          label: 'Unit Actions',
          items: [
            {
              id: 'view-unit-menu-item',
              label: 'View Unit Details',
              icon: <Icon icon="mdi:information-outline" />,
              action: () => {
                navigate(viewPath);
              },
              permit: {
                action: 'view',
                subject: unit as Unit,
              },
            },
            {
              id: 'edit-unit-menu-item',
              label: 'Edit Unit Details',
              icon: <EditIcon fontSize="small" />,
              action: () => {
                navigate(editPath);
              },
              permit: {
                action: 'update',
                subject: unit as Unit,
              },
            },
            {
              id: 'charts-unit-menu-item',
              label: 'View Charts',
              icon: <QueryStatsIcon fontSize="small" />,
              action: () => {
                navigate(pathname + '/charts');
              },
              permit: {
                action: 'viewCharts',
                subject: unit as Unit,
              },
            },
          ],
        });

        items.push({
          label: 'Thermostat Schedule',
          items: [
            {
              id: 'schedule-unit-menu-item',
              label: 'Schedule',
              icon: <ScheduleIcon fontSize="small" />,
              action: () => {
                navigate(pathname + '/schedule');
              },
            },
            {
              id: 'schedule-unit-template-menu-item',
              label: 'Schedule Templates',
              icon: <Icon icon="akar-icons:paper" />,
              action: () => {
                navigate(`/properties/${property._id}/schedule/templates`);
              },
            },
          ],
        });

        setTitle(property.title ?? 'Untitled Property');
        setTitlePath(`/properties/${property._id}/summary`);

        let typeName;

        switch (unit.type) {
          case 'residential':
            typeName = isSmall ? 'residential' : 'Residential';
            break;
          case 'commonArea':
            typeName = isSmall ? 'common' : 'Common Area';
            break;
          case 'centralEquipment':
            typeName = isSmall ? 'central' : 'Central Equipment';
            break;
          default:
            typeName = isSmall ? 'unknown type' : 'Unknown Unit Type';
            break;
        }

        setSubtitle(`${unit?.name ?? 'Unnamed Unit'} (${typeName})`);
        setSubtitlePath(`/properties/${property._id}/units`);

        setSubtitleActionWidget({
          widget: (
            <IconButton
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.5)',
                marginRight: '6px',
                color: '##115311',
                backgroundColor: '#9aff9a',
                boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)',
              }}
              aria-label="View Charts"
              onClick={() => navigate(pathname + '/charts')}
            >
              <QueryStatsIcon fontSize="small" />
            </IconButton>
          ),
          permit: {
            action: 'viewCharts',
            subject: unit as Unit,
          },
        });

        setContextMenuItems(items.length ? items : undefined);
      } else {
        const basePropertyName = property.title ?? 'Untitled Property';

        setTitle(
          isSmall && basePropertyName.length < 19
            ? `Installing ${basePropertyName}`
            : basePropertyName,
        );
        setTitlePath(pathname.replace('installer', 'units'));

        setSubtitle(
          `Installing ${unit?.name ?? 'Unnamed Unit'} (${
            unit?.type ?? 'unknown type'
          })`,
        );
        setSubtitlePath(`/properties/${property._id}/installer`);
      }

      return () => {
        setTitle(undefined);
        setTitlePath(undefined);
        setSubtitle(undefined);
        setSubtitlePath(undefined);
        setContextMenuItems(undefined);
        setSubtitleActionWidget(undefined);
      };
    }
  }, [pathname, unit, property, navigate]);

  return error ? (
    <>
      <Typography variant="h4">ERROR</Typography>
      <Typography variant="body1">Error detail: {error.message}</Typography>
    </>
  ) : loading ? (
    <FullWidthLoadingSkeleton />
  ) : !isSmall ? (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
        overflow: 'scroll',
        '& .MuiCardHeader-root': {
          padding: '4px 10px !important',
          borderBottom: '1px solid darkgray',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
      }}
    >
      <CardContent
        sx={
          installerView
            ? {
                margin: '0px',
                height: '100%',
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflowY: 'scroll',
              }
            : {
                margin: '0px',
                padding: '10px 0px 0px 0px !important',
                marginBottom: isSmall ? '20px' : '6px',
                height: '100%',
                width: '100%',
              }
        }
      >
        <div
          style={
            installerView
              ? {
                  display: 'flex',
                  flexDirection: isSmall ? 'column' : 'row',
                  flexWrap: 'wrap',
                  alignItems: isSmall ? 'center' : 'start',
                  justifyContent: 'center',
                  margin: isSmall ? '0px 0px 60px 0px' : '0px',
                  paddingBottom: '30px',
                  width: '100%',
                }
              : {
                  display: 'flex',
                  flexDirection: isSmall ? 'column' : 'row',
                  flexWrap: 'wrap',
                  alignItems: isSmall ? 'center' : 'start',
                  justifyContent: 'center',
                  margin: '0px',
                  paddingBottom: isSmall ? '60px' : '0px',
                }
          }
        >
          {unit?.property ? (
            groupings.length > 0 ? (
              groupings.map((grouping: DeviceGrouping) => (
                <DeviceGroupingDetail
                  key={grouping._id}
                  property={property as Partial<Property>}
                  unit={unit as Partial<Unit>}
                  grouping={grouping}
                  propertySetpointProfile={unit.property?.setpointLimitProfile}
                  unitSetpointProfile={unit.setpointLimitProfile}
                  preferredUnits={user?.preferredUnits ?? Fahrenheit}
                  preferredAirFlowUnits={
                    user?.preferredAirFlowUnits ?? CubicFeetPerMinute
                  }
                  installerView={installerView}
                />
              ))
            ) : (
              <div>No Zones or Panels Installed.</div>
            )
          ) : null}
        </div>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'top',
        flex: 1,
        alignItems: 'stretch',
        margin: isSmall ? '0px' : '20px',
        '& .MuiCardHeader-root': {
          padding: '4px 10px !important',
          borderBottom: '1px solid darkgray',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
        ...(installerView
          ? {
              paddingBottom: isSmall ? '60px' : '0px',
            }
          : {
              marginBottom: '10px',
              justifyContent: 'center',
            }),
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '2px 0px 0px 0px',
          padding: '0px !important',
          width: '100%',
        }}
      >
        {unit?.property ? (
          groupings.length > 0 ? (
            groupings.map((grouping: DeviceGrouping) => (
              <DeviceGroupingDetail
                key={grouping._id}
                property={property as Partial<Property>}
                unit={unit as Partial<Unit>}
                grouping={grouping}
                propertySetpointProfile={unit.property?.setpointLimitProfile}
                unitSetpointProfile={unit.setpointLimitProfile}
                preferredUnits={user?.preferredUnits ?? Fahrenheit}
                preferredAirFlowUnits={
                  user?.preferredAirFlowUnits ?? CubicFeetPerMinute
                }
                installerView={installerView}
              />
            ))
          ) : (
            <div>No Zones or Panels Installed.</div>
          )
        ) : null}
      </CardContent>
    </Card>
  );
}
