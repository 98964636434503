import React from 'react';
/* MUI */
import { Typography, Box, Switch } from '@mui/material';
/* Shared */
import BorderedSection from '../../shared/borderedSection';
import './device-info.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useUpdateDevicePowerStateMutation } from '../../../../types/generated-types';

const commonStyles = {
  borderBottom: 1,
  borderColor: 'grey.500',
  marginTop: '4px',
  marginBottom: '4px',
};

export type DeviceInformation = Record<string, string | Record<string, string>>;
export interface DevicePowerControl {
  deviceId: string;
  isPoweredOn: boolean;
  timestamp: string;
  hasPendingUpdates: boolean;
}

export function DeviceInfo({
  infoEntries,
  devicePowerControl,
}: {
  infoEntries?: DeviceInformation[] | Record<string, DeviceInformation>;
  devicePowerControl?: DevicePowerControl | undefined | null;
}) {
  const [updateDevicePowerState, { data, error, loading }] =
    useUpdateDevicePowerStateMutation();
  if (
    infoEntries &&
    ((Array.isArray(infoEntries) && infoEntries.length > 0) ||
      Object.keys(infoEntries).length > 0)
  ) {
    const isGrouped = !Array.isArray(infoEntries || []);

    return (
      <>
        {devicePowerControl ? (
          <BorderedSection
            style={{
              maxWidth: 'calc(100vw - 27px)',
              margin: '10px 6px 16px 6px',
              padding: '0px',
              backgroundColor: '#f4f5e6',
            }}
            ccStyle={
              isGrouped
                ? { padding: '4px 0px 0px 0px' }
                : { marginTop: '-10px', marginBottom: '-10px' }
            }
            title="Device Power Control"
          >
            <FormControlLabel
              control={
                <Switch
                  checked={devicePowerControl.isPoweredOn}
                  disabled={
                    !devicePowerControl.deviceId ||
                    devicePowerControl.hasPendingUpdates
                  }
                  size="small"
                  color="primary"
                  onChange={async (event) => {
                    const newSetting = event.target.checked;
                    console.log(
                      `Changing power status to ${newSetting ? 'ON' : 'OFF'}`,
                    );
                    await updateDevicePowerState({
                      variables: {
                        deviceId: devicePowerControl?.deviceId,
                        poweredOn: newSetting,
                      },
                    });
                  }}
                />
              }
              label={
                devicePowerControl.isPoweredOn
                  ? 'Device is ON'
                  : 'Device is OFF'
              } // This is the label that appears to the right
              labelPlacement="end" // Ensures the label appears to the right of the switch
            />
          </BorderedSection>
        ) : null}
        <BorderedSection
          style={
            isGrouped
              ? {
                  maxWidth: 'calc(100vw - 27px)',
                  margin: '10px 6px 16px 6px',
                  padding: '0px',
                  backgroundColor: '#f4f5e6',
                }
              : {
                  maxWidth: 'calc(100vw - 20px)',
                  margin: '10px 6px 16px 6px',
                }
          }
          ccStyle={
            isGrouped
              ? { padding: '4px 0px 0px 0px' }
              : { marginTop: '-10px', marginBottom: '-10px' }
          }
          title="Device Details"
        >
          <div
            style={{
              padding: '5px',
              fontSize: '13px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <>
              {!isGrouped
                ? ((infoEntries as DeviceInformation[]) || []).map(
                    (item, index) => {
                      const resNodes: React.ReactNode[] = [];
                      if (index > 0) {
                        resNodes.push(
                          <Box key={`box_${index}`} sx={{ ...commonStyles }} />,
                        );
                      }
                      Object.entries(item).forEach(([key, value], index2) => {
                        if (typeof value === 'string') {
                          resNodes.push(
                            <Typography
                              component="div"
                              key={`a_${index}_${index2}`}
                              sx={{
                                color: '#636363',
                                fontSize: '13px',
                                backgroundColor:
                                  index2 % 2 === 1 ? '#e1e1e1' : 'transparent',
                              }}
                            >
                              <div className="pair">
                                <span
                                  className="left"
                                  style={{
                                    backgroundColor:
                                      value === 'true' || value === 'True'
                                        ? 'lightgreen'
                                        : 'transparent',
                                  }}
                                >
                                  {`${key}:`}
                                </span>
                                <span className="right">{`${value}`}</span>
                              </div>
                            </Typography>,
                          );
                        } else {
                          const subResNodes: React.ReactNode[] = [];
                          Object.entries(value).forEach(
                            ([key2, value2], index2) => {
                              subResNodes.push(
                                <Typography
                                  key={`b_${index}_${index2}`}
                                  sx={{
                                    color: '#636363',
                                    fontSize: '13px',
                                  }}
                                >
                                  {key2}: {value2}
                                </Typography>,
                              );
                            },
                          );
                          resNodes.push(
                            <div
                              key={`c_${index}`}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                backgroundColor:
                                  index % 2 === 1 ? '#e1e1e1' : 'transparent',
                              }}
                            >
                              {subResNodes.map((child) => child)}
                            </div>,
                          );
                        }
                      });
                      return resNodes;
                    },
                  )
                : Object.keys(infoEntries ?? {}).map((key, index) => {
                    const myInfoEntries = infoEntries as Record<
                      string,
                      DeviceInformation
                    >;
                    const resNodes: React.ReactNode[] = [];
                    const item = myInfoEntries[key];
                    Object.entries(item).forEach(([key, value], index2) => {
                      if (typeof value === 'string') {
                        resNodes.push(
                          <Typography
                            component="div"
                            key={`a_${index}_${index2}`}
                            sx={{
                              color: '#636363',
                              fontSize: '13px',
                              backgroundColor:
                                index2 % 2 === 1 ? '#e1e1e1' : 'transparent',
                            }}
                          >
                            <div className="pair">
                              <span
                                className="left"
                                style={{
                                  backgroundColor:
                                    value === 'true' || value === 'True'
                                      ? 'lightgreen'
                                      : 'transparent',
                                }}
                              >
                                {`${key}:`}
                              </span>
                              <span className="right">{`${value}`}</span>
                            </div>
                          </Typography>,
                        );
                      } else {
                        const subResNodes: React.ReactNode[] = [];
                        Object.entries(value).forEach(
                          ([key2, value2], index2) => {
                            subResNodes.push(
                              <Typography
                                key={`b_${index}_${index2}`}
                                sx={{
                                  color: '#636363',
                                  fontSize: '13px',
                                }}
                              >
                                {`${key2}: ${value2}`}
                              </Typography>,
                            );
                          },
                        );
                        resNodes.push(
                          <div
                            key={`c_${index}`}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              backgroundColor:
                                index % 2 === 1 ? '#e1e1e1' : 'transparent',
                            }}
                          >
                            {subResNodes.map((child) => child)}
                          </div>,
                        );
                      }
                    });
                    return (
                      <div
                        key={`info-top-${index}`}
                        style={{ marginTop: index === 0 ? '-6px' : '15px' }}
                      >
                        <BorderedSection
                          style={{
                            backgroundColor: 'white',
                            borderColor: 'darkgrey',
                          }}
                          ccStyle={{ padding: '2px' }}
                          title={key}
                        >
                          {resNodes}
                        </BorderedSection>
                      </div>
                    );
                  })}
            </>
          </div>
        </BorderedSection>
      </>
    );
  }
  return (
    <BorderedSection
      style={{ margin: '10px 6px 16px 6px' }}
      ccStyle={{ marginTop: '-10px', marginBottom: '-10px' }}
      title="Device Details"
    >
      No details available.
    </BorderedSection>
  );
}
