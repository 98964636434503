import React from 'react';
import dayjs from 'dayjs';

/* GQL Types & Util */
import {
  useChillerDetailQuery,
  useChillerDetailUpdateSubscription,
  Chiller,
} from '../../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';

/* Util */
import { convertToTempUnits } from '../util/convert-temp-units';

/* Shared UI */
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import {
  DeviceInformation,
  DevicePowerControl,
} from '../shared-ui/device-info';
import { PanelFrame } from '../shared-ui/panel-frame';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { PoweredDownDevice } from '../shared-ui/powered-down-device';

export function ChillerComponent({
  deviceId,
  preferredUnits = Fahrenheit,
}: {
  deviceId: string;
  preferredUnits: TemperatureUnit;
}) {
  const [device, setDevice] = React.useState<Partial<Chiller>>();
  const {
    data,
    // TODO: Loading_error: deal with loading/error for boiler details.
    // loading,
    // error
  } = useChillerDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = React.useState<DeviceInformation[]>([]);
  const [devicePowerControl, setDevicePowerControl] =
    React.useState<DevicePowerControl | null>(null);

  useChillerDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  React.useEffect(() => {
    if (data?.chillerById) {
      setDevice(data.chillerById as unknown as Partial<Chiller>);
    }
  }, [data]);

  React.useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Panel: device.panel?.displayName ?? 'Unnamed',
        'Panel ID': device.panelId ?? 'Unidentified',
        'Panel Type': device.panel?.type ?? 'Unknown',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          [device.isPoweredOn ? 'Powered On Since' : 'Powered Off Since']:
            dayjs(
              device.powerState?.timestamp ?? device.timestamp ?? Date.now(),
            ).fromNow(),
          'Last Updated': dayjs(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  React.useEffect(() => {
    if (device?.powerState) {
      setDevicePowerControl({
        deviceId: device.deviceId ?? device._id ?? 'Unidentified',
        isPoweredOn: !!device.isPoweredOn,
        timestamp: dayjs(device.powerState.timestamp).toISOString(),
        hasPendingUpdates: device.hasPendingUpdates ?? false,
      });
    }
  }, [device?.powerState, device?.hasPendingUpdates]);

  /**
   * hasReadingsFor
   * Check whether 'devicemetrics' exist for a given reading or array of readings.
   * @param metricName string or array of strings to be evaluated
   * @returns boolean
   */
  const hasReadingsFor = React.useCallback(
    (metricName: string | Array<string>) => {
      const availableMetricNames = (device?.deviceMetrics ?? []).map(
        (metric) => metric?.key,
      );
      const targetMetrics = Array.isArray(metricName)
        ? metricName
        : [metricName];
      return availableMetricNames.some((n) => n && targetMetrics.includes(n));
    },
    [device],
  );

  return device && device.panel ? (
    <PanelFrame
      iconType="mdi:fridge-industrial"
      panel={device.panel}
      infoEntries={deviceInfo}
      //devicePowerControl={devicePowerControl}
      editingEnabled={false}
      isPoweredOn={device.isPoweredOn}
    >
      {device.isPoweredOn ? (
        <ChillerReadings
          preferredUnits={preferredUnits}
          chilledReturnTemperature={
            device.chilledReturnTemperature?.value ?? undefined
          }
          chilledSupplyTemperature={
            device.chilledSupplyTemperature?.value ?? undefined
          }
          ambientTemperature={device.ambientTemperature?.value ?? undefined}
          coolSetpoint={device.coolSetpoint?.value ?? undefined}
          hasReadingsFor={hasReadingsFor}
        />
      ) : (
        <PoweredDownDevice />
      )}
    </PanelFrame>
  ) : (
    <DeviceLoadingSkeleton size="large" />
  );
}

interface ChillerModuleProps {
  moduleName: string;
  children: React.ReactNode;
}
function ChillerModule(props: ChillerModuleProps) {
  const { moduleName, children } = props;
  return (
    <BorderedSection style={{ marginTop: '10px' }} title={moduleName}>
      {children}
    </BorderedSection>
  );
}

interface ChillerReadingsProps {
  preferredUnits: TemperatureUnit;
  chilledSupplyTemperature?: number;
  chilledReturnTemperature?: number;
  coolSetpoint?: number;
  ambientTemperature?: number;
  hasReadingsFor: (m: string | string[]) => boolean;
}
function ChillerReadings(props: ChillerReadingsProps) {
  const {
    preferredUnits,
    chilledSupplyTemperature,
    chilledReturnTemperature,
    coolSetpoint,
    ambientTemperature,
    hasReadingsFor,
  } = props;
  return (
    <>
      <BorderedSection
        title="Temperature Readings"
        labelStyle={{ fontSize: '10px' }}
        ccStyle={{
          padding: '4px 4px 6px 4px',
          marginLeft: '4px',
          margin: '0px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
        style={{ width: '100%' }}
      >
        <BorderedSection
          title="Inlet"
          labelStyle={{ fontSize: '10px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          ccStyle={{ padding: '6px' }}
          visible={hasReadingsFor('chilledSupplyTemperature')}
        >
          <DisplayValue
            value={convertToTempUnits(chilledSupplyTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
            style={{ width: '100%', textAlign: 'center' }}
          />
        </BorderedSection>
        <BorderedSection
          title="Delta"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor([
            'chilledSupplyTemperature',
            'chilledReturnTemperature',
          ])}
        >
          <DisplayValue
            value={getAbsoluteDelta(
              convertToTempUnits(chilledReturnTemperature, preferredUnits) ?? 0,
              convertToTempUnits(chilledSupplyTemperature, preferredUnits) ?? 0,
            )}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
            blankZeros={true}
          />
        </BorderedSection>
        <BorderedSection
          title="Outlet"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '4px', margin: '0px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor('chilledReturnTemperature')}
        >
          <DisplayValue
            value={convertToTempUnits(chilledReturnTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
        <BorderedSection
          title="Setpoint"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor('coolSetpoint')}
        >
          <DisplayValue
            value={convertToTempUnits(coolSetpoint, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
        <BorderedSection
          title="Ambient"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={
            hasReadingsFor('ambientTemperature') &&
            ambientTemperature !== undefined
          }
        >
          <DisplayValue
            value={convertToTempUnits(ambientTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
      </BorderedSection>
    </>
  );
}

/**
 * getAbsoluteDelta - In the context of a boiler, the difference between outlet and inlet should always be a positive number.
 * A negative delta should trigger a warning, but this is not the place to do so.  Therefore we return an absolute value.
 * @param inlet
 * @param outlet
 * @returns
 */
function getAbsoluteDelta(inlet: number, outlet: number): number {
  return Math.abs(outlet - inlet);
}
