import React from 'react';

export const PoweredDownDevice = () => {
  return (
    <div style={{ width: '100%' }}>
      <h1
        style={{
          width: '100%',
          textAlign: 'center',
          marginTop: '-4px',
          color: 'rgba(0, 0, 0, 0.87)',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 500,
        }}
      >
        Powered Down
      </h1>
      <p
        style={{
          width: '100%',
          textAlign: 'center',
          marginTop: '-20px',
          color: 'rgba(0, 0, 0, 0.87)',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 400,
        }}
      >
        This device is currently powered down.
      </p>
    </div>
  );
};
