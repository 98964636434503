import React, { useEffect, useState } from 'react';

/* Local Components */
import { DeviceGrouping } from '../units/unit-detail';
import {
  Boiler,
  Chiller,
  Controller,
  DoorSensor,
  EnumDKeyDeviceType,
  EnumDKeyDeviceType as EnumDeviceType,
  EnumPanelType,
  Gpio,
  IndoorAirHandler,
  IndoorSensor,
  LeakDetector,
  Loop,
  ModbusAirHandler,
  OccupancySensor,
  OpenWeather,
  Panel,
  PowerMonitor,
  PressureSensor,
  SetpointProfile,
  SmartPowerOutlet,
  Thermistor,
  Thermostat,
  VsdPump,
  WindowSensor,
  Z210Pump,
  Zone,
  Property,
  Unit,
} from '../../../types/generated-types';
import { LeakDetectorSensorComponent } from '../devices/leak-detector/leak-detector-sensor';
import { ThermostatComponent } from '../devices/thermostat/thermostat';
import { MultiSensorComponent } from '../devices/multi-sensor/multi-sensor';
import { EmbueError } from '../../system/models/embueError';
import { BoilerComponent } from '../devices/boiler/boiler';
import { ChillerComponent } from '../devices/chiller/chiller';
import { WindowSensorComponent } from '../devices/window-sensor/window-sensor';
import { DoorSensorComponent } from '../devices/door-sensor/door-sensor';
import { LoopComponent } from '../devices/loop/loop';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OccupancySensorComponent } from '../devices/occupancy-sensor/occupancy-sensor';
import { Maybe } from '../../../types/generated-types';
import { PowerMonitorComponent } from '../devices/power-monitor/power-monitor';
import { ThermistorComponent } from '../devices/thermistor/thermistor';
import { PressureSensorComponent } from '../devices/pressure-sensor/pressureSensor';
import { GpioPanelComponent } from '../devices/gpio-panel/gpioPanel';
import { OpenWeatherComponent } from '../devices/open-weather/openWeather';
import {
  AirFlowUnit,
  CubicFeetPerMinute,
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { InstallableDevice } from '../installer/installable-device';
import { InstallationStatusIndicator } from '../devices/shared-ui/installation-status-indicator';
import { SmartPowerOutletComponent } from '../devices/smart-power-outlet/smart-power-outlet';
import { ModbusAirHandlerComponent } from '../devices/modbus-air-handler/modbus-air-handler';
import BorderedSection from '../shared/borderedSection';
import { useAuthenticator } from '../../auth/AuthenticationContext';

import './device-grouping-detail.css';

interface DeviceGroupingDetailProps {
  grouping: DeviceGrouping;
  propertySetpointProfile?: Maybe<SetpointProfile>;
  unitSetpointProfile?: Maybe<SetpointProfile>;
  preferredUnits?: TemperatureUnit;
  preferredAirFlowUnits?: AirFlowUnit;
  installerView?: boolean;
  property: Partial<Property>;
  unit: Partial<Unit>;
}

export interface GroupingDevices {
  airHandler: Partial<IndoorAirHandler> | undefined;
  thermostat: Partial<Thermostat> | undefined;
  pairedSensor: Partial<IndoorSensor> | undefined;
  smartPowerOutlet: Partial<SmartPowerOutlet> | undefined;
  modbusAirHandler: Partial<ModbusAirHandler> | undefined;
  otherThermostats: Partial<Thermostat>[];
  otherMultiSensors: Partial<IndoorSensor>[];
  otherAirHandlers: Partial<IndoorAirHandler>[];
  windowSensors: Partial<WindowSensor>[];
  doorSensors: Partial<DoorSensor>[];
  occupancySensors: Partial<OccupancySensor>[];
  leakDetectors: Partial<LeakDetector>[];
  thermistors: Partial<Thermistor>[];
  boilers: Partial<Boiler>[];
  chillers: Partial<Chiller>[];
  gpios: Partial<Gpio>[];
  loops: Partial<Loop>[];
  pressureSensors: Partial<PressureSensor>[];
  powerMonitors: Partial<PowerMonitor>[];
  controllers: Partial<Controller>[];
  vsdPumps: Partial<VsdPump>[];
  z210Pumps: Partial<Z210Pump>[];
  openWeatherSensors: Partial<OpenWeather>[];
}

export function DeviceGroupingDetail(props: DeviceGroupingDetailProps) {
  const {
    grouping,
    property,
    unit,
    propertySetpointProfile,
    unitSetpointProfile,
    preferredUnits = Fahrenheit,
    preferredAirFlowUnits = CubicFeetPerMinute,
    installerView = false,
  } = props;

  const [groupingDevices, setGroupingDevices] = useState<GroupingDevices>();
  const [isResident, setIsResident] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClick = () => setOpen(!open);
  const devicesCount = grouping.devices?.length ?? 0;
  const { user } = useAuthenticator();

  useEffect(() => {
    if (user) {
      setIsResident(user.isResident);
    }
  }, [user]);

  /**
   * iterate over the grouping collection (the devices within a zone or panel) and
   * populate the groupingDevices Object that stores these devices keyed by their type.
   */
  useEffect(() => {
    if (grouping && grouping.devices) {
      const _groupingDevices: GroupingDevices = {
        airHandler: undefined,
        thermostat: undefined,
        pairedSensor: undefined,
        smartPowerOutlet: undefined,
        modbusAirHandler: undefined,
        otherAirHandlers: [],
        otherThermostats: [],
        otherMultiSensors: [],
        windowSensors: [],
        doorSensors: [],
        occupancySensors: [],
        leakDetectors: [],
        thermistors: [],
        boilers: [],
        chillers: [],
        gpios: [],
        loops: [],
        pressureSensors: [],
        powerMonitors: [],
        controllers: [],
        vsdPumps: [],
        z210Pumps: [],
        openWeatherSensors: [],
      };

      grouping.devices
        .filter((device) => installerView || device.isConfigured)
        .forEach((device) => {
          switch (device.type) {
            case EnumDeviceType.IndoorAirHandler:
              if ((grouping as Partial<Zone>).airHandlerId === device._id) {
                _groupingDevices.airHandler = device;
              } else {
                _groupingDevices.otherAirHandlers.push(device);
              }
              break;
            case EnumDeviceType.Thermostat:
              if ((grouping as Partial<Zone>).thermostatId === device._id) {
                _groupingDevices.thermostat = device;
              } else {
                _groupingDevices.otherThermostats.push(device);
              }
              break;
            case EnumDeviceType.IndoorSensor:
              if ((grouping as Partial<Zone>).pairedSensorId === device._id) {
                _groupingDevices.pairedSensor = device;
              } else {
                _groupingDevices.otherMultiSensors.push(device);
              }
              break;
            case EnumDeviceType.SmartPowerOutlet:
              _groupingDevices.smartPowerOutlet = device;
              break;
            case EnumDeviceType.ModbusAirHandler:
              if (!isResident) {
                _groupingDevices.modbusAirHandler = device;
              }
              break;
            case EnumDeviceType.WindowSensor:
              _groupingDevices.windowSensors.push(device);
              break;
            case EnumDeviceType.DoorSensor:
              _groupingDevices.doorSensors.push(device);
              break;
            case EnumDeviceType.OccupancySensor:
              _groupingDevices.occupancySensors.push(device);
              break;
            case EnumDeviceType.LeakDetector:
              _groupingDevices.leakDetectors.push(device);
              break;
            case EnumDeviceType.Thermistor:
              _groupingDevices.thermistors.push(device);
              break;
            case EnumDeviceType.Boiler:
              _groupingDevices.boilers.push(device);
              break;
            case EnumDeviceType.Chiller:
              _groupingDevices.chillers.push(device);
              break;
            case EnumDeviceType.Gpio:
              _groupingDevices.gpios.push(device);
              break;
            case EnumDeviceType.Loop:
              _groupingDevices.loops.push(device);
              break;
            case EnumDeviceType.PressureSensor:
              _groupingDevices.pressureSensors.push(device);
              break;
            case EnumDeviceType.PowerMonitor:
              _groupingDevices.powerMonitors.push(device);
              break;
            case EnumDeviceType.Controller:
              _groupingDevices.controllers.push(device);
              break;
            case EnumDeviceType.VsdPump:
              _groupingDevices.vsdPumps.push(device);
              break;
            case EnumDeviceType.Z210Pump:
              _groupingDevices.z210Pumps.push(device);
              break;
            case EnumDeviceType.OpenWeather:
              _groupingDevices.openWeatherSensors.push(device);
              break;
            default:
              console.error(
                `Unsupported device type: ${device.type}: ${JSON.stringify(device, null, 2)}`,
              );
          }
        });

      setGroupingDevices(_groupingDevices);
    }
  }, [grouping, installerView, isResident]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  if (groupingDevices) {
    if (grouping.__typename === 'Zone') {
      return installerView ? (
        <List
          subheader={
            <ListItemButton onClick={handleClick}>
              <InstallationStatusIndicator
                isDone={(grouping.devices ?? []).every(
                  (device) => device?.installation?.status === 3,
                )}
              />
              <ListItemText
                primary={
                  <Typography sx={{ p: 1 }} variant={'h3'}>
                    {grouping.name}: {devicesCount} Device
                    {devicesCount !== 1 && 's'}
                  </Typography>
                }
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          }
          dense
          sx={{
            width: {
              xs: '95%',
              sm: '75%',
            },
            borderStyle: 'solid',
            borderColor: 'lightslategray',
            m: 1,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {groupingDevices.thermostat?._id ? (
              <InstallableDevice
                key={groupingDevices.thermostat._id}
                device={groupingDevices.thermostat}
              />
            ) : null}
            {groupingDevices.pairedSensor?._id ? (
              <InstallableDevice
                key={groupingDevices.pairedSensor._id}
                device={groupingDevices.pairedSensor}
              />
            ) : null}
            {groupingDevices.smartPowerOutlet?._id ? (
              <InstallableDevice
                key={groupingDevices.smartPowerOutlet._id}
                device={groupingDevices.smartPowerOutlet}
              />
            ) : null}
            {groupingDevices.otherThermostats.map(
              (device) =>
                device?._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}
            {groupingDevices.otherMultiSensors.map(
              (device) =>
                device?._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}

            {groupingDevices.windowSensors.map(
              (device) =>
                device._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}
            {groupingDevices.doorSensors.map(
              (device) =>
                device._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}
            {groupingDevices.occupancySensors.map(
              (device) =>
                device?._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}
            {groupingDevices.leakDetectors.map(
              (device) =>
                device?._id && (
                  <InstallableDevice key={device._id} device={device} />
                ),
            )}
          </Collapse>
        </List>
      ) : (
        <div
          className={`device-grouping-detail-${isSmall ? 'small' : 'large'}`}
          style={{
            width: isSmall ? '100%' : '400px',
            display: isSmall ? 'block' : 'flex',
            padding: isSmall ? '0px 2px 0px 3px' : '4px',
          }}
        >
          <BorderedSection
            title={
              groupingDevices.thermostat?._id ||
              groupingDevices.modbusAirHandler?._id
                ? ''
                : grouping.name
                  ? grouping.name.split('_').join(' ')
                  : 'Unnamed Zone'
            }
            style={{
              width: '100%',
              maxWidth: isSmall ? 'unset' : '400px',
              margin: isSmall ? '0px 0px 12px 0px' : '0px',
            }}
            ccStyle={{
              padding: '3px',
              overflow: 'auto',
              marginTop: groupingDevices.thermostat?._id ? '0px' : '-10px',
            }}
          >
            {groupingDevices.thermostat?._id ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  overflow: 'visible',
                }}
              >
                <ThermostatComponent
                  id={groupingDevices.thermostat._id}
                  property={property}
                  unit={unit}
                  propertySetpointProfile={propertySetpointProfile}
                  unitSetpointProfile={unitSetpointProfile}
                  preferredUnits={preferredUnits}
                  pairedSensor={groupingDevices.pairedSensor}
                  smartPowerOutlet={groupingDevices.smartPowerOutlet}
                />
              </div>
            ) : null}
            {groupingDevices.pairedSensor?._id ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <MultiSensorComponent
                  deviceId={groupingDevices.pairedSensor._id}
                  preferredUnits={preferredUnits}
                  pairedThermostat={groupingDevices.thermostat}
                />
              </div>
            ) : null}
            {groupingDevices.modbusAirHandler?._id ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <ModbusAirHandlerComponent
                  id={groupingDevices.modbusAirHandler._id}
                  preferredUnits={preferredUnits}
                  preferredAirFlowUnits={preferredAirFlowUnits}
                  pairedThermostat={groupingDevices.thermostat}
                />
              </div>
            ) : null}

            {groupingDevices.smartPowerOutlet?._id ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <SmartPowerOutletComponent
                  deviceId={groupingDevices.smartPowerOutlet._id}
                  pairedThermostat={groupingDevices.thermostat}
                />
              </div>
            ) : null}

            {groupingDevices.otherThermostats.map(
              (device) =>
                device?._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <ThermostatComponent
                      id={device._id}
                      property={property}
                      unit={unit}
                      propertySetpointProfile={propertySetpointProfile}
                      unitSetpointProfile={unitSetpointProfile}
                      preferredUnits={preferredUnits}
                    />
                  </div>
                ),
            )}
            {groupingDevices.otherMultiSensors.map(
              (device) =>
                device?._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <MultiSensorComponent
                      deviceId={device._id}
                      preferredUnits={preferredUnits}
                    />
                  </div>
                ),
            )}

            {groupingDevices.windowSensors.map(
              (device) =>
                device._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <WindowSensorComponent deviceId={device._id} />
                  </div>
                ),
            )}
            {groupingDevices.doorSensors.map(
              (device) =>
                device._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <DoorSensorComponent deviceId={device._id} />
                  </div>
                ),
            )}
            {groupingDevices.occupancySensors.map(
              (device) =>
                device?._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <OccupancySensorComponent deviceId={device._id} />
                  </div>
                ),
            )}
            {groupingDevices.leakDetectors.map(
              (device) =>
                device?._id && (
                  <div key={device._id} style={{ width: '100%' }}>
                    <LeakDetectorSensorComponent deviceId={device._id} />
                  </div>
                ),
            )}
          </BorderedSection>
        </div>
      );
    } else if (grouping.__typename === 'Panel') {
      if (installerView) {
        /* installer view does not apply to panel type groupings */
        return null;
      }

      const panel = grouping as Partial<Panel>;
      // const panelType = panel.type;
      const firstDevice = (panel.devices ?? [])[0] ?? {};

      switch (panel.type) {
        case EnumPanelType.Device:
          return panel.role === 'PowerMonitor' ? (
            (groupingDevices.powerMonitors ?? [])[0]?._id ? (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  overflow: 'auto',
                  margin: isSmall ? '6px 0px 6px 0px' : '5px',
                  padding: '0px',
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    marginTop: '0px',
                    marginBottom: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    overflow: 'visible',
                  }}
                >
                  <PowerMonitorComponent
                    deviceId={groupingDevices.powerMonitors[0]._id ?? 'nothing'}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
              >
                No Power Monitors Installed
              </div>
            )
          ) : panel.role === 'Boiler' ? (
            (groupingDevices.boilers ?? [])[0]?._id ? (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  margin: isSmall ? '0px 0px 6px 0px' : '5px',
                  padding: '0px',
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    marginTop: '0px',
                    marginBottom: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  <BoilerComponent
                    deviceId={groupingDevices.boilers[0]._id ?? 'nothing'}
                    preferredUnits={preferredUnits}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
              >
                No Boilers Installed
              </div>
            )
          ) : panel.role === 'Chiller' ? (
            (groupingDevices.chillers ?? [])[0]?._id ? (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  margin: isSmall ? '0px 0px 6px 0px' : '5px',
                  padding: '0px',
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    marginTop: '0px',
                    marginBottom: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  <ChillerComponent
                    deviceId={groupingDevices.chillers[0]._id ?? 'nothing'}
                    preferredUnits={preferredUnits}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`device-grouping-detail-${
                  isSmall ? 'small' : 'large'
                }`}
              >
                No Chillers Installed
              </div>
            )
          ) : panel.type === 'Device' ? (
            <div
              className={`device-grouping-detail-${
                isSmall ? 'small' : 'large'
              }`}
              style={{
                width: '100%',
                maxWidth: '400px',
                margin: isSmall ? '0px 0px 6px 0px' : '5px',
                padding: '0px',
                borderRadius: '4px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                {firstDevice.type === EnumDKeyDeviceType.Thermistor ? (
                  <ThermistorComponent
                    deviceId={(panel.devices ?? [])[0]._id ?? 'nothing'}
                    label={panel.name ?? panel.role ?? 'Unnamed Thermistor'}
                    embedded={false}
                    preferredUnits={preferredUnits}
                  />
                ) : firstDevice.type === EnumDKeyDeviceType.PressureSensor ? (
                  <PressureSensorComponent
                    deviceId={(panel.devices ?? [])[0]._id ?? 'nothing'}
                    label={
                      panel.name ?? panel.role ?? 'Unnamed Pressure Sensor'
                    }
                  />
                ) : firstDevice.type === EnumDKeyDeviceType.OpenWeather ? (
                  <OpenWeatherComponent
                    deviceId={(panel.devices ?? [])[0]._id ?? 'nothing'}
                    label={
                      panel.name ??
                      panel.role ??
                      'Unnamed Outdoor Temperature Sensor'
                    }
                    embedded={false}
                  />
                ) : firstDevice.type ? (
                  <div>
                    <div>{`Unknown Device Type: ${
                      ((panel.devices ?? [])[0] ?? {}).type
                    }`}</div>
                    <pre>{JSON.stringify(panel, null, 2)}</pre>
                  </div>
                ) : (
                  <div>No Devices Found</div>
                )}
              </div>
            </div>
          ) : (
            <div
              className={`device-grouping-detail-${
                isSmall ? 'small' : 'large'
              }`}
            >
              <pre>{JSON.stringify(panel, null, 2)}</pre>
            </div>
          );
        case EnumPanelType.Loop:
          return (
            <div
              className={`device-grouping-detail-${
                isSmall ? 'small' : 'large'
              }`}
              style={{
                width: '100%',
                maxWidth: '400px',
                overflow: 'auto',
                margin: isSmall ? '0px 0px 6px 0px' : '5px',
                padding: '0px',
                borderRadius: '4px',
              }}
            >
              <LoopComponent
                groupingDevices={groupingDevices}
                panelId={panel?._id ?? ''}
                preferredUnits={preferredUnits}
              />
            </div>
          );
        case EnumPanelType.Gpio:
          return (
            <div
              className={`device-grouping-detail-${
                isSmall ? 'small' : 'large'
              }`}
              style={{
                width: '100%',
                maxWidth: '400px',
                overflow: 'auto',
                margin: isSmall ? '0px 0px 6px 0px' : '5px',
                padding: 'px',
                borderRadius: '4px',
              }}
            >
              <GpioPanelComponent
                panelId={panel._id ?? ''}
                role={panel.role || 'GPIO'}
                gpioDevices={panel.devices as Partial<Gpio>[]}
              />
            </div>
          );
        default:
          return (
            <div
              className={`device-grouping-detail-${
                isSmall ? 'small' : 'large'
              }`}
            >
              Unknown Panel Type
            </div>
          );
      }
    } else {
      /* In this case the grouping is neither a zone nor a panel.
       * Very strange indeed, best to do nothing.
       */
      console.warn(
        '[device-grouping-detail] device grouping is neither a zone or a panel',
      );
      return null;
    }
  } else {
    return (
      <div className={`device-grouping-detail-${isSmall ? 'small' : 'large'}`}>
        No Devices
      </div>
    );
  }
}
