import React, { useCallback, useEffect, useState } from 'react';
import {
  Gpio,
  Panel,
  PressureSensor,
  Thermistor,
  useLoopDetailQuery,
  useLoopDetailUpdateSubscription,
  useLoopPumpsUpdateSubscription,
  useSwapPumpsMutation,
  useUpdatePumpStateMutation,
  VsdPump,
  Z210Pump,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { GroupingDevices } from '../../deviceGroupings/device-grouping-detail';
import { ThermistorComponent } from '../thermistor/thermistor';
import { ThermistorPairComponent } from '../thermistor-pair/thermistor-pair';
import { VsdPumpComponent } from '../vsd-pump/vsd-pump';

import { PressureSensorComponent } from '../pressure-sensor/pressureSensor';
import { PressureSensorPairComponent } from '../pressure-sensor-pair/pressure-sensor-pair';
import {
  handleModelUpdateEvent,
  updateCacheFromSubscriptionEvent,
} from '../../../../helpers/subscriptionUtils';
import { MenuAction, PanelFrame, ViewMode } from '../shared-ui/panel-frame';
import { Z210PumpComponent } from '../z-210-pump/z-210-pump';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';
import { DeviceInformation } from '../shared-ui/device-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import moment from 'moment';
import { Notifier } from '../../../system/services/notificationManager';
import { UpdatePending } from '../thermostat/components';
import { titleize } from 'inflected';
import dayjs from 'dayjs';

interface LoopModel {
  primaryPump?: Partial<VsdPump>;
  secondaryPump?: Partial<VsdPump>;
  supplyThermistor?: Partial<Thermistor>;
  returnThermistor?: Partial<Thermistor>;
  heatSupplyThermistor?: Partial<Thermistor>;
  heatReturnThermistor?: Partial<Thermistor>;
  coolSupplyThermistor?: Partial<Thermistor>;
  coolReturnThermistor?: Partial<Thermistor>;
  supplyPressureSensor?: Partial<PressureSensor>;
  returnPressureSensor?: Partial<PressureSensor>;
  z210Pump?: Partial<Z210Pump>;
}

export function LoopComponent({
  panelId,
  groupingDevices,
  preferredUnits = Fahrenheit,
}: {
  panelId: string;
  groupingDevices: GroupingDevices;
  preferredUnits: TemperatureUnit;
}) {
  const [loopDevices, setLoopDevices] = useState<LoopModel>();
  const [isPumpOnline, setIsPumpOnline] = useState<boolean[]>([false, false]);
  const [panel, setPanel] = useState<Partial<Panel>>();
  const [initialPrimaryState, setInitialPrimaryState] = useState<number>(-1);
  const [currentPrimaryState, setCurrentPrimaryState] = useState<number>(-1);
  const [initialSecondaryState, setInitialSecondaryState] =
    useState<number>(-1);
  const [currentSecondaryState, setCurrentSecondaryState] =
    useState<number>(-1);
  // TODO: Loading_error: revisit showing some sort of loading / loading error indicator
  // const [primaryLoading, setPrimaryLoading] = useState<boolean>(false);
  // const [secondaryLoading, setSecondaryLoading] = useState<boolean>(false);
  // const [pumpsLoading, setPumpsLoading] = useState<boolean>(false);
  // const [primaryError, setPrimaryError] = useState<boolean>(false);
  // const [secondaryError, setSecondaryError] = useState<boolean>(false);
  // const [pumpsError, setPumpsError] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.NORMAL);
  const [hasPrimaryPump, setHasPrimaryPump] = useState<boolean>(false);
  const [hasSecondaryPump, setHasSecondaryPump] = useState<boolean>(false);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [primaryUpdatePending, setPrimaryUpdatePending] =
    useState<boolean>(false);
  const [secondaryUpdatePending, setSecondaryUpdatePending] =
    useState<boolean>(false);
  const [pumpIds, setPumpIds] = useState<string[]>([]);
  const [
    swapPumps,
    { data: swapPumpsResult, error: swapPumpsError, loading: swapPumpsLoading },
  ] = useSwapPumpsMutation();
  const [
    updatePumpState,
    {
      data: updatePumpResult,
      // TODO: Loading_error: handle update pump error!
      // error: updatePumpError,
      loading: updatePumpLoading,
    },
  ] = useUpdatePumpStateMutation();
  const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(false);

  const subscriptionHandler = useCallback(
    (data: any) => {
      const myData = data?.data?.data?.vSDPumpEventsByIds;
      if (myData) {
        if (myData._id === loopDevices?.primaryPump?._id) {
          /* Updating Primary Pump Values */
          setIsPumpOnline((current) => [
            (myData.alertTypes && myData.alertTypes.deviceOffline) ||
              (myData.alertTypes && myData.alertTypes.sourceOffline) ||
              false,
            current[1],
          ]);
          setPrimaryUpdatePending(myData.hasPendingUpdates);
          if (secondaryUpdatePending || myData.hasPendingUpdates) {
            if (viewMode === ViewMode.UPDATING) {
              setViewMode(ViewMode.AWAITING);
            } else if (
              [ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(viewMode)
            ) {
              Notifier.warn(
                'This Loop is currently being updated. Any changes you have made have been rolled back. Please wait while we update your view.',
              );
              setCurrentPrimaryState(initialPrimaryState);
              setCurrentSecondaryState(initialSecondaryState);
              setViewMode(ViewMode.AWAITING);
            } else if (viewMode === ViewMode.AWAITING) {
              // already in awaiting .... wait for next pump to be updated.
              setInitialPrimaryState(myData.stopOrStart.value);
              setCurrentPrimaryState(myData.stopOrStart.value);
            } else {
              Notifier.warn(
                'This Loop is currently being updated. Please wait while we apply that update.',
              );
              setViewMode(ViewMode.AWAITING);
            }
          }
          if (!myData.hasPendingUpdates && viewMode !== ViewMode.NORMAL) {
            setInitialPrimaryState(myData.stopOrStart.value);
            setCurrentPrimaryState(myData.stopOrStart.value);
            if (!secondaryUpdatePending) {
              Notifier.success('Loop configuration updated successfully.');
              setViewMode(ViewMode.NORMAL);
            }
          }
        } else {
          /* Updating Secondary Pump Values */
          setIsPumpOnline((current) => [
            current[0],
            (myData.alertTypes && myData.alertTypes.deviceOffline) ||
              (myData.alertTypes && myData.alertTypes.sourceOffline) ||
              false,
          ]);
          setSecondaryUpdatePending(myData.hasPendingUpdates);
          if (primaryUpdatePending || myData.hasPendingUpdates) {
            if (viewMode === ViewMode.UPDATING) {
              setViewMode(ViewMode.AWAITING);
            } else if (
              [ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(viewMode)
            ) {
              Notifier.warn(
                'This Loop is currently being updated. Any changes you have made have been rolled back. Please wait while we update your view.',
              );
              setCurrentPrimaryState(initialPrimaryState);
              setCurrentSecondaryState(initialSecondaryState);
              setViewMode(ViewMode.AWAITING);
            } else if (viewMode === ViewMode.AWAITING) {
              // already in awaiting.... wait for next pump to be updated.
              setInitialSecondaryState(myData.stopOrStart.value);
              setCurrentSecondaryState(myData.stopOrStart.value);
            } else {
              Notifier.warn(
                'This Loop is currently being updated. Please wait while we apply that update.',
              );
              setViewMode(ViewMode.AWAITING);
            }
          }
          if (!myData.hasPendingUpdates && viewMode !== ViewMode.NORMAL) {
            setInitialSecondaryState(myData.stopOrStart.value);
            setCurrentSecondaryState(myData.stopOrStart.value);
            if (!primaryUpdatePending) {
              Notifier.success('Loop configuration updated successfully.');
              setViewMode(ViewMode.NORMAL);
            }
          }
        }
      }
    },
    [
      viewMode,
      loopDevices,
      initialPrimaryState,
      initialSecondaryState,
      primaryUpdatePending,
      secondaryUpdatePending,
    ],
  );

  useLoopPumpsUpdateSubscription({
    fetchPolicy: 'no-cache',
    variables: {
      ids: pumpIds,
    },
    onData: handleModelUpdateEvent(subscriptionHandler),
  });

  useEffect(() => {
    if (swapPumpsError) {
      Notifier.error(swapPumpsError.message);
    } else if (swapPumpsResult?.swapVSDPumpOnOffState) {
      const result = swapPumpsResult.swapVSDPumpOnOffState;
      const primary = result[0].stopOrStart?.value;
      const secondary = result[1].stopOrStart?.value;
      if (
        primary === undefined ||
        primary === null ||
        secondary === undefined ||
        secondary === null
      ) {
        Notifier.warn(
          'Swap operation returned an invalid value. Please contact Embue support.',
        );
      } else {
        setInitialPrimaryState(primary);
        setInitialSecondaryState(secondary);
        setCurrentPrimaryState(primary);
        setCurrentSecondaryState(secondary);
        setDataChanged(false);
        setViewMode(ViewMode.AWAITING);
      }

      setInitialPrimaryState(result[0].stopOrStart?.value ?? 0);
      setInitialSecondaryState(result[1].stopOrStart?.value ?? 0);
      setCurrentSecondaryState(result[1].stopOrStart?.value ?? 0);
      setCurrentSecondaryState(result[1].stopOrStart?.value ?? 0);
    }
    if (
      processing &&
      !(swapPumpsResult || swapPumpsError) &&
      !(swapPumpsLoading || updatePumpLoading)
    ) {
      setProcessing(false);
    }
  }, [swapPumpsResult, swapPumpsError, swapPumpsLoading, updatePumpResult]);

  const {
    data,
    // TODO: Loading_error: handle loading/error for loop detail queries.
    // loading,
    // error,
  } = useLoopDetailQuery({
    variables: { id: panelId },
  });

  useLoopDetailUpdateSubscription({
    variables: { ids: [panelId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.panel) {
      setPanel(data.panel as Partial<Panel>);
    }
  }, [data]);

  /* set the array of ids to be used in the useLoopPumpsUpdateSubscription */
  useEffect(() => {
    if (loopDevices) {
      const ids = [];
      if (loopDevices.primaryPump?._id) {
        ids.push(loopDevices.primaryPump._id);
      }
      if (loopDevices.secondaryPump?._id) {
        ids.push(loopDevices.secondaryPump._id);
      }
      setPumpIds(ids);
    }
  }, [loopDevices]);

  /**
   *  iterate over the list of panel devices (groupingDevices) supplied in the component props
   *  and assemble a collection of loop component devices.
   **/
  useEffect(() => {
    if (groupingDevices) {
      const _loopDevices: LoopModel = {};
      for (const thermistor of groupingDevices.thermistors) {
        switch (thermistor.role) {
          case 'Supply':
            _loopDevices.supplyThermistor = thermistor;
            break;

          case 'Return':
            _loopDevices.returnThermistor = thermistor;
            break;
          case 'HeatSupply':
            _loopDevices.heatSupplyThermistor = thermistor;
            break;
          case 'HeatReturn':
            _loopDevices.heatReturnThermistor = thermistor;
            break;
          case 'CoolSupply':
            _loopDevices.coolSupplyThermistor = thermistor;
            break;
          case 'CoolReturn':
            _loopDevices.coolReturnThermistor = thermistor;
            break;
          default:
          // do nothing by default.
        }
      }

      for (const pressureSensor of groupingDevices.pressureSensors) {
        switch (pressureSensor.role) {
          case 'Supply':
            _loopDevices.supplyPressureSensor = pressureSensor;
            break;
          case 'Return':
            _loopDevices.returnPressureSensor = pressureSensor;
            break;
          default:
          // do nothing by default.
        }
      }

      for (const pump of groupingDevices.vsdPumps) {
        switch (pump.role) {
          case 'Primary':
            _loopDevices.primaryPump = pump;
            setHasPrimaryPump(true);
            break;
          case 'Secondary':
            _loopDevices.secondaryPump = pump;
            setHasSecondaryPump(true);
            break;
          default:
          // do nothing by default.
        }
      }

      if (groupingDevices.z210Pumps?.length > 0) {
        _loopDevices.z210Pump = groupingDevices.z210Pumps[0];
        // TODO: Peter: this was the setter for a useState variable which
        //   was never read anywhere. I don't think we need this at this point.
        // setHasZ210Pump(true);
      }

      const primaryOffline =
        _loopDevices.primaryPump?.alertTypes?.deviceOffline ||
        _loopDevices.primaryPump?.alertTypes?.sourceOffline ||
        false;
      const secondaryOffline =
        _loopDevices.secondaryPump?.alertTypes?.deviceOffline ||
        _loopDevices.secondaryPump?.alertTypes?.sourceOffline ||
        false;

      setIsPumpOnline([!primaryOffline, !secondaryOffline]);
      setLoopDevices(_loopDevices);
    }
  }, [groupingDevices]);

  /**
   * iterate over the list of panel devices (groupingDevices) supplied in the component props
   * and assemble a collection of info items to be displayed in the expand info view.
   */
  useEffect(() => {
    if (panel && groupingDevices) {
      const panelInfo: DeviceInformation = {
        Panel: panel.displayName ?? 'Unnamed',
        'Panel ID': panel._id ?? 'Unidentified',
        'Panel Type': panel.type ?? 'Unknown',
        Source: panel.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] =
        panel.devices?.map((device: Partial<Gpio>) => {
          return {
            'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
            Name: device.name ?? 'Unnamed',
            Type: device.typeDisplayName ?? 'Unknown',
            State: device.state?.value ? 'Alert' : 'Ok',
            'Last Updated': moment(device.timestamp).fromNow(),
          };
        }) ?? [];

      function roleAndType(
        device: Partial<VsdPump | Z210Pump | Thermistor | PressureSensor>,
      ) {
        return (
          device.role &&
          device.typeDisplayName &&
          `${titleize(device.role)} ${device.typeDisplayName}`
        );
      }

      groupingDevices.vsdPumps.forEach((device: Partial<VsdPump>) => {
        devicesInfo.push({
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? roleAndType(device) ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          [device.isPoweredOn ? 'Powered On Since' : 'Powered Off Since']:
            dayjs(
              device.powerState?.timestamp ?? device.timestamp ?? Date.now(),
            ).fromNow(),
          'Last Updated': moment(device.timestamp).fromNow(),
        });
      });

      groupingDevices.z210Pumps.forEach((device) => {
        devicesInfo.push({
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? roleAndType(device) ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          'Last Updated': moment(device.timestamp).fromNow(),
        });
      });

      groupingDevices.thermistors.forEach((device) => {
        devicesInfo.push({
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name:
            device.name ??
            roleAndType(device) ??
            device.displayName ??
            'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          'Last Updated': moment(device.timestamp).fromNow(),
        });
      });

      groupingDevices.pressureSensors.forEach((device) => {
        devicesInfo.push({
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name:
            device.name ??
            roleAndType(device) ??
            device.displayName ??
            'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          'Last Updated': moment(device.timestamp).fromNow(),
        });
      });

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [panel, groupingDevices]);

  useEffect(() => {
    if (dataChanged) {
      if (viewMode === ViewMode.EDIT_UNCHANGED) {
        setViewMode(ViewMode.EDIT);
      }
    } else {
      if (viewMode === ViewMode.EDIT) {
        setViewMode(ViewMode.EDIT_UNCHANGED);
      }
    }
  }, [dataChanged]);

  const handleRevertChanges = useCallback(() => {
    setCurrentPrimaryState(initialPrimaryState);
    setCurrentSecondaryState(initialSecondaryState);
    setDataChanged(false);
    setViewMode(ViewMode.NORMAL);
  }, [initialPrimaryState, initialSecondaryState]);

  useEffect(() => {
    setIsEditingEnabled(
      hasPrimaryPump && hasSecondaryPump && isPumpOnline[0] && isPumpOnline[1],
    );
  }, [hasSecondaryPump, hasSecondaryPump, isPumpOnline]);
  // function isEditingEnabled() {
  //   /**
  //    * Confirm that both primary and secondary pump are online!
  //    * what happens if there is only one pump? or three?
  //    * this function does not handle those cases gracefully.
  //    */
  //   return hasPrimaryPump && hasSecondaryPump && isPumpOnline[0] && isPumpOnline[1];
  // }

  async function handleMenuButtonClick(action: MenuAction) {
    switch (action) {
      case MenuAction.CANCEL:
        setCurrentPrimaryState(initialPrimaryState);
        setCurrentSecondaryState(initialSecondaryState);
        setDataChanged(false);
        setViewMode(ViewMode.NORMAL);
        break;
      case MenuAction.EDIT_VALUES:
        setViewMode(ViewMode.EDIT_UNCHANGED);
        break;
      case MenuAction.REVERT_CHANGES:
        handleRevertChanges();
        break;
      case MenuAction.SAVE_CHANGES:
        if (hasPrimaryPump && loopDevices?.primaryPump?._id) {
          if (hasSecondaryPump && loopDevices?.secondaryPump?._id) {
            if (initialPrimaryState !== currentPrimaryState) {
              if (initialSecondaryState !== currentSecondaryState) {
                // Both pumps changed state.
                if (currentPrimaryState !== currentSecondaryState) {
                  // Need to swap pumps.
                  setViewMode(ViewMode.UPDATING);
                  setTimeout(async () => {
                    const updateTimer = setTimeout(
                      () => setViewMode(ViewMode.NORMAL),
                      60000,
                    );
                    try {
                      if (
                        loopDevices?.primaryPump?._id &&
                        loopDevices.secondaryPump?._id
                      ) {
                        await swapPumps({
                          variables: {
                            primaryPumpId: loopDevices.primaryPump._id,
                            secondaryPumpId: loopDevices.secondaryPump._id,
                          },
                        });
                      }
                      clearTimeout(updateTimer);
                    } catch (error) {
                      Notifier.error(
                        'Received error while updating Loop status',
                        { error },
                      );
                      clearTimeout(updateTimer);
                    }
                  }, 500);
                } else {
                  // Error condition. Both pumps changed ... but changed to the SAME state. Not possible if their initial states were not corrupted.
                  if (currentPrimaryState === 1) {
                    Notifier.warn(
                      'Unable to update pump states. Both pumps cannot be running at the same time.',
                    );
                  } else {
                    Notifier.warn(
                      'Unable to update pump states. Pumps are in an inconsistent state. Contact Embue support.',
                    );
                  }
                }
              } else {
                // No change in secondary. Toggle primary pump.
                setViewMode(ViewMode.UPDATING);
                setTimeout(async () => {
                  const updateTimer = setTimeout(
                    () => setViewMode(ViewMode.NORMAL),
                    60000,
                  );
                  try {
                    if (loopDevices.primaryPump?._id) {
                      await updatePumpState({
                        variables: {
                          id: loopDevices.primaryPump._id,
                          stopOrStart: currentPrimaryState,
                        },
                      });
                    }
                    clearTimeout(updateTimer);
                  } catch (error) {
                    Notifier.error(
                      'Received error while updating Loop status',
                      { error },
                    );
                    clearTimeout(updateTimer);
                  }
                });
              }
            } else if (initialSecondaryState !== currentSecondaryState) {
              // No change in primary. Toggle secondary pump.
              setViewMode(ViewMode.UPDATING);

              setTimeout(async () => {
                const updateTimer = setTimeout(
                  () => setViewMode(ViewMode.NORMAL),
                  60000,
                );
                try {
                  if (loopDevices.secondaryPump?._id) {
                    await updatePumpState({
                      variables: {
                        id: loopDevices.secondaryPump._id,
                        stopOrStart: currentSecondaryState,
                      },
                    });
                  }
                  clearTimeout(updateTimer);
                } catch (error) {
                  Notifier.error('Received error while updating Loop status', {
                    error,
                  });
                  clearTimeout(updateTimer);
                }
              });
            } else {
              Notifier.warn(
                'Unable to update pump states. No changes to save.',
              );
            }
          } else {
            if (initialPrimaryState !== currentPrimaryState) {
              setViewMode(ViewMode.UPDATING);

              setTimeout(async () => {
                const updateTimer = setTimeout(
                  () => setViewMode(ViewMode.NORMAL),
                  60000,
                );
                try {
                  if (loopDevices.primaryPump?._id) {
                    await updatePumpState({
                      variables: {
                        id: loopDevices.primaryPump._id,
                        stopOrStart: currentPrimaryState,
                      },
                    });
                  }
                  clearTimeout(updateTimer);
                } catch (error) {
                  Notifier.error('Received error while updating Loop status', {
                    error,
                  });
                  clearTimeout(updateTimer);
                }
              });
            } else {
              Notifier.warn(
                'Unable to update primary pump state. No changes to save.',
              );
            }
          }
        } else if (hasSecondaryPump && loopDevices?.secondaryPump?._id) {
          if (initialSecondaryState !== currentSecondaryState) {
            // simply toggle the secondary pump status.
            setViewMode(ViewMode.UPDATING);

            setTimeout(async () => {
              const updateTimer = setTimeout(
                () => setViewMode(ViewMode.NORMAL),
                60000,
              );
              try {
                if (loopDevices.secondaryPump?._id) {
                  await updatePumpState({
                    variables: {
                      id: loopDevices.secondaryPump._id,
                      stopOrStart: currentSecondaryState,
                    },
                  });
                }
                clearTimeout(updateTimer);
              } catch (error) {
                Notifier.error('Received error while updating Loop status', {
                  error,
                });
                clearTimeout(updateTimer);
              }
            });
          } else {
            Notifier.warn(
              'Unable to update secondary pump state as no change in state was detected.',
            );
          }
        } else {
          Notifier.warn('Unable to update any pump state. No pumps detected.');
        }
        break;
    }
  }

  return loopDevices && panel ? (
    <PanelFrame
      iconType="fe:loop"
      panel={panel}
      infoEntries={deviceInfo}
      viewMode={viewMode}
      editingEnabled={isEditingEnabled}
      editable={true}
      handleMenuButtonClick={handleMenuButtonClick}
    >
      <div style={{ padding: '0px 4px 0px 4px' }}>
        <BorderedSection
          title={`Loop Readings`}
          labelStyle={{ fontSize: '10px' }}
          style={{
            display: 'flex',
            marginBottom: '-6px',
          }}
          ccStyle={{
            padding: '4px ',
            margin: '4px',
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {loopDevices.supplyThermistor?._id ||
            loopDevices.returnThermistor?._id ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {loopDevices.supplyThermistor?._id && (
                  <ThermistorComponent
                    deviceId={loopDevices.supplyThermistor._id}
                    label="Supply Temp"
                    style={{
                      margin: '0px 3px 6px 0px',
                      marginRight: loopDevices.returnThermistor?._id
                        ? '3px'
                        : '6px',
                    }}
                    preferredUnits={preferredUnits}
                  />
                )}
                {loopDevices.supplyThermistor?._id &&
                loopDevices.returnThermistor?._id ? (
                  <ThermistorPairComponent
                    supplyId={loopDevices.supplyThermistor._id}
                    returnId={loopDevices.returnThermistor._id}
                    label="Delta"
                    style={{
                      margin: '0px 3px 6px 3px',
                    }}
                    preferredUnits={preferredUnits}
                  />
                ) : null}
                {loopDevices.returnThermistor?._id && (
                  <ThermistorComponent
                    deviceId={loopDevices.returnThermistor._id}
                    label="Return Temp"
                    style={{
                      margin: '0px 0px 6px 3px',
                      marginLeft: loopDevices.returnThermistor?._id
                        ? '3px'
                        : '6px',
                    }}
                    preferredUnits={preferredUnits}
                  />
                )}
              </div>
            ) : null}

            {loopDevices.supplyPressureSensor?._id ||
            loopDevices.returnPressureSensor?._id ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop:
                    loopDevices.supplyThermistor?._id ||
                    loopDevices.returnThermistor?._id
                      ? '6px'
                      : '0px',
                }}
              >
                {loopDevices.supplyPressureSensor?._id && (
                  <PressureSensorComponent
                    deviceId={loopDevices.supplyPressureSensor._id}
                    label="Supply Pressure"
                    style={{
                      margin: '0px 3px 6px 0px',
                      marginRight: loopDevices.returnThermistor?._id
                        ? '3px'
                        : '6px',
                    }}
                  />
                )}
                {loopDevices.supplyPressureSensor?._id &&
                loopDevices.returnPressureSensor?._id ? (
                  <PressureSensorPairComponent
                    supplyId={loopDevices.supplyPressureSensor._id}
                    returnId={loopDevices.returnPressureSensor._id}
                    label="Delta"
                    style={{
                      margin: '0px 3px 6px 3px',
                    }}
                  />
                ) : null}
                {loopDevices.returnPressureSensor?._id && (
                  <PressureSensorComponent
                    deviceId={loopDevices.returnPressureSensor._id}
                    label="Return Pressure"
                    style={{
                      margin: '0px 0px 6px 3px',
                      marginLeft: loopDevices.returnThermistor?._id
                        ? '3px'
                        : '6px',
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </BorderedSection>
        {viewMode === ViewMode.UPDATING ? (
          <UpdatePending status={'submitted'} padding={10} />
        ) : null}
        {viewMode === ViewMode.AWAITING ? (
          <UpdatePending status={'awaiting'} padding={10} />
        ) : null}
        {![ViewMode.UPDATING, ViewMode.AWAITING].includes(viewMode) &&
          loopDevices.primaryPump?._id && (
            <VsdPumpComponent
              editing={[ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(
                viewMode,
              )}
              value={currentPrimaryState}
              deviceId={loopDevices.primaryPump._id}
              onOperatingModeChange={(newOperatingMode: number) => {
                const initialDeviceState: number = initialPrimaryState;
                if (initialDeviceState === -1) {
                  setInitialPrimaryState(newOperatingMode);
                }
                setCurrentPrimaryState(newOperatingMode);
                setDataChanged(
                  initialSecondaryState !== currentSecondaryState ||
                    (initialDeviceState !== -1 &&
                      initialDeviceState !== newOperatingMode),
                );
              }}
              editingEnabled={
                [ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(viewMode) &&
                currentSecondaryState === 0
              }
            />
          )}

        {![ViewMode.UPDATING, ViewMode.AWAITING].includes(viewMode) &&
          loopDevices.secondaryPump?._id && (
            <VsdPumpComponent
              editing={[ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(
                viewMode,
              )}
              value={currentSecondaryState}
              deviceId={loopDevices.secondaryPump._id}
              onOperatingModeChange={
                (newOperatingMode: number) => {
                  const initialDeviceState: number = initialSecondaryState;
                  if (initialDeviceState === -1) {
                    setInitialSecondaryState(newOperatingMode);
                  }
                  setCurrentSecondaryState(newOperatingMode);
                  setDataChanged(
                    initialPrimaryState !== currentPrimaryState ||
                      (initialDeviceState !== -1 &&
                        initialDeviceState !== newOperatingMode),
                  );
                }
                // : undefined
              }
              editingEnabled={
                [ViewMode.EDIT, ViewMode.EDIT_UNCHANGED].includes(viewMode) &&
                currentPrimaryState === 0
              }
            />
          )}
        {loopDevices.z210Pump?._id && (
          <Z210PumpComponent deviceId={loopDevices.z210Pump._id} />
        )}
      </div>
    </PanelFrame>
  ) : (
    <DeviceLoadingSkeleton size="large" />
  );
}
